import React from 'react';

const TermsConditions = () => {
  return (
    <section className="container mx-auto my-8 px-4">
      <h2 className="text-3xl font-bold mb-4" style={{ color: '#6145E1' }}>Terms and Conditions</h2>
      <p>Welcome to FontifyText.com! These terms and conditions outline the rules and regulations for the use of our website.</p>
      <p>By accessing this website, we assume you accept these terms and conditions. If you do not agree to all of the terms stated on this page, do not continue to use FontifyText.</p>

      <h2>1. License</h2>
      <p>Unless otherwise stated, FontifyText owns the intellectual property rights for all material on this website. All intellectual property rights are reserved. You may access this content for your personal use, subject to restrictions set in these terms.</p>
      <p>You must not:</p>
      <ul>
        <li>Republish material from FontifyText.com</li>
        <li>Sell, rent, or sub-license material from FontifyText.com</li>
        <li>Reproduce, duplicate, or copy material from FontifyText.com</li>
        <li>Redistribute content from FontifyText.com</li>
      </ul>

      <h2>2. User-Generated Content</h2>
      <p>Certain areas of this website may allow users to generate and post content. FontifyText does not claim ownership over such content; however, by posting content on our site, you grant us a non-exclusive license to use, reproduce, edit, and authorize others to use and reproduce your content in any format.</p>

      <h2>3. Acceptable Use</h2>
      <p>You agree not to use FontifyText.com in any way that may damage, impair, or overburden our website or interfere with anyone else’s use of our services. You are also prohibited from engaging in unlawful activities, including but not limited to spamming, hacking, or uploading harmful material.</p>

      <h2>4. Disclaimer</h2>
      <p>All the materials on FontifyText.com are provided "as is." FontifyText makes no warranties, whether expressed or implied, and disclaims all other warranties, including without limitation, implied warranties or conditions of merchantability and fitness for a particular purpose.</p>

      <h2>5. Limitation of Liability</h2>
      <p>In no event shall FontifyText, its directors, employees, or affiliates be liable for any damages arising out of the use or inability to use our services, even if FontifyText has been notified of the possibility of such damages.</p>

      <h2>6. Governing Law</h2>
      <p>These terms shall be governed and construed in accordance with the laws of [Your Country], and you submit to the non-exclusive jurisdiction of the state and federal courts located in [Your State/Country] for the resolution of any disputes.</p>

      <h2>7. Changes to Terms</h2>
      <p>FontifyText reserves the right to modify these terms at any time. Your continued use of the website after any changes signifies your acceptance of the new terms.</p>
    </section>
  );
};

export default TermsConditions;
