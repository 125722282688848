import React, { useState, useEffect } from 'react';
import transformText from '../transformText';

const FontCard = ({ selectedFontName, fontData, searchText, isDarkMode }) => {
  const [fontsMap, setFontsMap] = useState({});
  const [fontCharMaps, setFontCharMaps] = useState({});
  const [fontPlaceholders, setFontPlaceholders] = useState({});
  const [copied, setCopied] = useState(false);
  const [transformedText, setTransformedText] = useState('');

  useEffect(() => {
    if (fontData && typeof fontData === 'object') {
      let tempFontsMap = {};
      let tempFontCharMaps = {};
      let tempFontPlaceholders = {};

      // Populate fontsMap
      tempFontsMap[fontData.fontName] = fontData.font;

      // Populate fontPlaceholders
      tempFontPlaceholders[fontData.fontName] = fontData.sampleChars;

      // Populate fontCharMaps
      let charArray = fontData.fontChars.split('|');
      let charMapping = {};

      // Assuming A-Z and a-z as the standard characters to map
      const standardChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

      // Map each standard character to the corresponding font character
      for (let i = 0; i < standardChars.length; i++) {
        charMapping[standardChars[i]] = charArray[i] || ''; // Handle cases where the array might not have enough characters
      }

      tempFontCharMaps[fontData.fontName] = charMapping;

      // Set state with the processed data
      setFontsMap(tempFontsMap);
      setFontCharMaps(tempFontCharMaps);
      setFontPlaceholders(tempFontPlaceholders);
    } else {
      console.error('Unexpected data structure: fontData should be an object');
    }
  }, [fontData]); // Dependency array ensures this runs only when fontData changes

  useEffect(() => {
    // Ensure the transformedText is updated after fontsMap, fontCharMaps, and fontPlaceholders are set
    if (Object.keys(fontsMap).length > 0 && Object.keys(fontCharMaps).length > 0 && Object.keys(fontPlaceholders).length > 0) {
      setTransformedText(transformText(fontsMap, fontCharMaps, fontPlaceholders, searchText, selectedFontName));
    }
  }, [fontsMap, fontCharMaps, fontPlaceholders, searchText, selectedFontName]);

  const handleCopy = () => {
    navigator.clipboard.writeText(transformedText);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <div className={`mt-4 p-4 rounded-lg shadow-md mb-4 border ${isDarkMode ? 'bg-gray-800 text-white border-gray-700' : 'bg-white text-gray-900 border-gray-300'}`}>
      <h2 className={`text-xl font-bold mb-2 ${isDarkMode ? 'text-purple-300' : 'text-purple-600'}`}>{selectedFontName}</h2>
      <div className={`p-2 border rounded-lg flex items-center justify-between mb-4 ${isDarkMode ? 'border-gray-700' : 'border-gray-300'}`}>
        <input
          type="text"
          value={transformedText}
          readOnly
          className={`flex-1 p-2 ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-900'} border-none outline-none`}
        />
        <button
          onClick={handleCopy}
          className={`p-2 rounded ${copied ? 'bg-purple-600 text-white' : isDarkMode ? 'bg-gray-700 text-white border border-purple-600 hover:bg-purple-600 hover:text-white' : 'bg-white text-purple-600 border border-purple-600 hover:bg-purple-600 hover:text-white'}`}
        >
          {copied ? 'Copied' : 'Copy'}
        </button>
      </div>
    </div>
  );
};

export default FontCard;
