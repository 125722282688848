import React from 'react';

const Header = ({ toggleDarkMode, isDarkMode }) => {
  return (
    <header className={isDarkMode ? 'bg-gray-800 py-4 shadow-md' : 'bg-white py-4 shadow-md'}>
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4">
        <div className="text-2xl md:text-3xl font-bold text-purple-600 text-center md:text-left">
          <a href="https://fontifytext.com/">
            <img 
              src='fontify-text-logo.png' 
              alt='FONTIFY TEXT - COPY PASTE FONT GENERATOR'
              className="w-[350px] h-[80px]" // Customize the width and height as needed
            />
          </a>
        </div>
        <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4 mt-4 md:mt-0 text-center md:text-left">
          <div className="text-lg">
            Transform your text into art with our{' '}
            <span className="font-bold text-purple-600">Fancy Font Generator!</span>
          </div>
          {/* Dark Mode Toggle with Label */}
          <div className="flex items-center space-x-2">
            <span className={isDarkMode ? 'text-white' : 'text-gray-800'}>Dark Mode</span>
            <button
              onClick={toggleDarkMode}
              className={`relative w-12 h-6 rounded-full focus:outline-none ${isDarkMode ? 'bg-purple-600' : 'bg-gray-200'}`}
            >
              <span
                className={`absolute left-1 top-1 w-4 h-4 rounded-full bg-white transition-transform transform ${isDarkMode ? 'translate-x-6' : ''}`}
              />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
