import React from 'react';

const PrivacyPolicy = () => {
  return (
    <section className="container mx-auto my-8 px-4">
      <h2 className="text-3xl font-bold mb-4" style={{ color: '#6145E1' }}>Privacy Policy</h2>
      <p>At FontifyText.com, we prioritize your privacy and are committed to protecting your personal information. This privacy policy explains what data we collect, how we use it, and your rights concerning your personal information.</p>

      <h2>1. Information We Collect</h2>
      <p>When you visit FontifyText.com, we may collect:</p>
      <ul>
        <li><strong>Personal Data:</strong> Your name, email address, and any other information you voluntarily provide when using our services.</li>
        <li><strong>Usage Data:</strong> Information about how you use our website, including your IP address, browser type, pages visited, and interaction data.</li>
      </ul>

      <h2>2. How We Use Your Data</h2>
      <p>FontifyText.com uses the collected data for various purposes:</p>
      <ul>
        <li>To provide and maintain our services</li>
        <li>To personalize your experience on our site</li>
        <li>To improve our website and its features</li>
        <li>To communicate with you, including responding to inquiries or sending updates</li>
        <li>To analyze usage patterns and enhance security</li>
      </ul>

      <h2>3. Data Sharing</h2>
      <p>We do not share, sell, or trade your personal information with third parties except in the following cases:</p>
      <ul>
        <li>When required by law</li>
        <li>To protect our legal rights</li>
        <li>With service providers who assist us in operating our website (with strict confidentiality agreements in place)</li>
      </ul>

      <h2>4. Cookies and Tracking Technologies</h2>
      <p>FontifyText.com uses cookies and similar tracking technologies to analyze trends, administer the site, and track users’ movements around the website. You can choose to disable cookies through your browser settings, but this may affect your ability to use certain features.</p>

      <h2>5. Data Security</h2>
      <p>We take data security seriously and implement appropriate measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of data transmission over the internet is 100% secure.</p>

      <h2>6. Your Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access, update, or delete your personal information.</li>
        <li>Object to or restrict the processing of your data.</li>
        <li>Withdraw consent where we rely on your consent for data processing.</li>
      </ul>
      <p>To exercise any of these rights, please contact us at info@fontifytext.com.</p>

      <h2>7. Third-Party Links</h2>
      <p>Our website may contain links to third-party sites that are not operated by us. We are not responsible for the privacy practices or content of these third-party sites.</p>

      <h2>8. Changes to This Policy</h2>
      <p>We may update our privacy policy from time to time. Any changes will be posted on this page with the updated date.</p>

      <h2>9. Contact Us</h2>
      <p>If you have any questions about this privacy policy, please contact us at info@fontifytext.com.</p>
    </section>

  );
};

export default PrivacyPolicy;
