import React from 'react';
import { Link } from 'react-router-dom';
import postsData from '../data/postsData'; // Import the posts data from postsData.js

const BlogPage = ({ isDarkMode }) => {
  return (
    <section className="container mx-auto my-8 px-4">
      <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Blog</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {postsData.map((post) => (
          <div
            key={post.id}
            className={`p-4 rounded-lg shadow-md border mb-4 ${isDarkMode ? 'bg-gray-800 text-white border-gray-700' : 'bg-white text-gray-900 border-gray-300'}`}
          >
            <img
              src={post.imageUrl}
              alt={post.title}
              className="w-full h-48 object-cover rounded-lg mb-4"
            />
            <h3 className={`text-xl font-bold mb-2 ${isDarkMode ? 'text-purple-300' : 'text-purple-600'}`}>
              {post.title}
            </h3>
            <p className="mb-4">{post.description}</p>
            <Link
              to={`/blog/${post.filename.replace('.md', '')}`}
              className={`px-4 py-2 rounded ${isDarkMode ? 'bg-gray-700 text-white border border-purple-600 hover:bg-purple-600' : 'bg-white text-purple-600 border border-purple-600 hover:bg-purple-600 hover:text-white'}`}
            >
              Read More
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
};

export default BlogPage;
