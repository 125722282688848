import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Header from './components/Header';
import SearchAndCategory from './components/SearchAndCategory';
import FontCard from './components/FontCard';
import BlogPosts from './components/BlogPosts';
import Footer from './components/Footer';
import BlogPage from './components/BlogPage';
import PostPage from './components/PostPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions';
import fontsDataJSON from './data/fontData.json'; // Import saved data

const App = () => {
  const [selectedCategory, setSelectedCategory] = useState('All Categories');
  const [searchText, setSearchText] = useState('');
  const [visibleCount, setVisibleCount] = useState(10);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [fontsData] = useState(fontsDataJSON); // Use saved data

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setVisibleCount(10);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const getFontsToDisplay = () => {
    return selectedCategory === 'All Categories'
      ? Object.values(fontsData).flat().slice(0, visibleCount)
      : fontsData[selectedCategory]?.slice(0, visibleCount) || [];
  };

  const loadMoreFonts = () => {
    setVisibleCount((prevCount) => prevCount + 10);
  };

  const totalFonts = selectedCategory === 'All Categories'
    ? Object.values(fontsData).flat().length
    : fontsData[selectedCategory]?.length || 0;

  return (
    <Router>
      <div className={isDarkMode ? 'bg-gray-900 text-white min-h-screen flex flex-col' : 'bg-white text-gray-900 min-h-screen flex flex-col'}>
        <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />
        <main className="container mx-auto my-8 flex-1 px-4">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <SearchAndCategory
                    setSearchText={setSearchText}
                    categories={Object.keys(fontsData)}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={handleCategoryChange}
                    isDarkMode={isDarkMode}
                  />
                  {getFontsToDisplay().map((font, index) => (
                    <FontCard
                      key={index}
                      selectedFontName={`${font.fontName}`}
                      fontData={font}
                      searchText={searchText}
                      isDarkMode={isDarkMode}
                    />
                  ))}
                  {visibleCount < totalFonts && (
                    <button
                      onClick={loadMoreFonts}
                      className={`px-4 py-2 mt-4 rounded ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-purple-600 border border-purple-600 hover:bg-purple-600 hover:text-white'} mx-auto block`}
                    >
                      Load More
                    </button>
                  )}
                  <BlogPosts isDarkMode={isDarkMode} />
                  <div className="text-center mt-8">
                    <Link
                      to="/blog"
                      className={`px-4 py-2 rounded ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-purple-600 border border-purple-600 hover:bg-purple-600 hover:text-white'}`}
                    >
                      View More
                    </Link>
                  </div>
                </>
              }
            />
            <Route path="/blog" element={<BlogPage isDarkMode={isDarkMode} />} />
            <Route path="/blog/:id" element={<PostPage isDarkMode={isDarkMode} />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy isDarkMode={isDarkMode} />} />
            <Route path="/terms-conditions" element={<TermsConditions isDarkMode={isDarkMode} />} />
          </Routes>
        </main>
        <Footer isDarkMode={isDarkMode} />
      </div>
    </Router>
  );
};

export default App;
