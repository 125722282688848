import React from 'react';

const Footer = ({ isDarkMode }) => {
  return (
    <footer className={`${isDarkMode ? 'bg-gray-800 text-white border-gray-700' : 'bg-white text-gray-900 border-gray-300'} py-4 shadow-md border-t text-center`}>
      <div className="container mx-auto px-4">
        <h3 className="text-xl font-bold text-purple-600"><a href="https://fontifytext.com/">FONTIFY TEXT - COPY PASTE FONT GENERATOR</a></h3>
        <p className="mb-4">Transform your text into art with our Fancy Font Generator!</p>
        <div className="flex justify-center space-x-4 mb-4">
          <a href="/privacy-policy" className="hover:underline">Privacy Policy</a>
          <a href="/terms-conditions" className="hover:underline">Terms and Conditions</a>
        </div>
        <p className="mb-2">&copy; 2024 - All rights reserved</p>
        <p>Designed and Developed by <a href="http://appjingle.com/">AppJingle Solutions</a></p>
      </div>
    </footer>
  );
};

export default Footer;
