const postsData = [
  {
    id: 1,
    title: "Fancy Fonts: Level Up Your Game's Style",
    description: "Let's face it, fancy fonts are captivating. They grab attention, evoke emotions, and add a unique touch to any project...",
    imageUrl: '/posts/images/image1.jpg',
    filename: 'fancy-fonts-for-gaming.md',
  },
  {
    id: 2,
    title: 'Creative Ways to Stand Out: Using Graphic Design and Branding',
    description: "In today's world, where information is constantly vying for our attention, it's more important than...",
    imageUrl: '/posts/images/image2.jpg',
    filename: 'creative-ways-to-stand-out.md',
  },
  {
    id: 3,
    title: 'Creative Ways to Boost Your Social Media Presence',
    description: 'Social media has become an indispensable part of our lives, a platform for connecting with friends, sharing experiences...',
    imageUrl: '/posts/images/image3.jpg',
    filename: 'creative-ways-to-boost-your-social-media-presence.md',
  },
];

export default postsData;
